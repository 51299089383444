import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { useAlternateLangs } from "../../components/Hreflangs";
import H from "../../components/Headline";

const breadCrumbLevels = {
  Hjem: "/",
  // Blogg: "/blog",
  Impressum: "no/imprint"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/imprint"
);

const Imprint = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Impressum"
        description="Informasjon om operatøren av nettstedet, samt juridiske merknader."
        lang="no"
        alternateLangs={alternateLangs}
        meta={[{ name: "robots", content: "noindex" }]}
      />
      <MainContent article={false}>
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />

        <H as="h1">Impressum</H>
        <p>Operatør av nettstedet bluerivermountains.com:</p>
        <p>
          Matthias Kupperschmidt
          <br />
          Frederikssundsvej 22
          <br />
          2400 København
          <br />
          Danmark
        </p>
        <p className="baseline">
          E-post: <a href="mailto:info@bluerivermountains.com">info@bluerivermountains.com</a>
          <br />
          Telefon: +45 93 86 85 64
        </p>
        <p className="baseline">MVA-nummer: 39474263</p>
        <p>Denne siden er opphavsrettslig beskyttet av Matthias Kupperschmidt.</p>
        <H as="h2">Juridisk</H>
        <p>
          Jeg gjennomgår jevnlig informasjonen på dette nettstedet. Til tross for all omsorg kan dataene ha endret seg i
          mellomtiden. Et ansvar eller garanti for aktualitet, korrekthet og fullstendighet av informasjonen kan derfor
          ikke aksepteres. Det samme gjelder nettsteder som det refereres til via hyperkoblinger. Matthias Kupperschmidt
          er ikke ansvarlig for innholdet på nettstedene som nås som følge av en slik tilkobling. Reproduksjon av
          informasjon eller data – spesielt bruk av tekster, deler av tekster eller bilder – krever forhåndssamtykke.
          Dette gjelder også for alle blogginnlegg. Jeg påpeker at jeg lagrer alle data som er angitt i søkemasken og
          ikke bruker disse dataene til egen reklame eller gir dem videre til tredjepart.
        </p>
        <p>
          Ytterligere informasjon finner du i min <Link to="/no/privacy-policy">personvernpolicy</Link>.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout>
);
export default Imprint;